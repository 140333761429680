import React, { Component } from "react"
import { Link } from "react-router-dom"
import logo from "../assets/bftvc-logo.svg"
import iconHome from "../assets/icon-home.svg"
import iconOurStory from "../assets/icon-our-story.svg"
import iconRoster from "../assets/icon-roster.svg"
import iconMenu from "../assets/icon-menu.svg"
import "./Header.css"

const icons = {
  home: iconHome,
  ourstory: iconOurStory,
  roster: iconRoster
}

class Header extends Component {
  render() {
    return (
      <div className="Header">
        <div className="wrapper">
          <Link to="/" className="Header-logo">
            <img src={logo} alt="BFTVC logo" />
          </Link>

          <div className="Header-nav">
            {this.props.mainItems.map((i, idx) => (
              <Link
                key={idx}
                to={`${i.id}`}
                className="Header-nav-item desktop"
                onClick={(e, id) => this.props.scroll(e, i.id)}
              >
                <div className={"icon " + i.id}>
                  <img src={icons[i.id]} alt="" />
                </div>
                <div className="label">{i.label}</div>
              </Link>
            ))}

            <a
              href="#more"
              className="Header-nav-item"
              onClick={e => this.props.toggleNav(e)}
            >
              <div className="icon menu">
                <img src={iconMenu} alt="" />
              </div>
              <div className="label">
                <span className="mobile">Menu</span>
                <span className="desktop">More</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
