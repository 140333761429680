import React, { Component } from 'react';
import './Home.css';
import title from '../assets/bftvc-title.svg';
import graphic from '../assets/bftvc-graphic.png';

class Home extends Component {
  render() {
    return (
      <div className="Home" id="home">
        <div className="wrapper">
          <div className="Home-graphic">
            <div className="Home-graphic-inset">
              <img src={graphic} alt="Graphic" className="Home-graphic-image" />
            </div>
          </div>

          <img src={title} alt="Basketball for the Vertically Challenged" className="Home-title" />
        </div>
      </div>
    );
  }
}

export default Home;
