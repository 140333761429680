import React, { Component } from "react";
import "./League.css";

class League extends Component {
  render() {
    return (
      <div className="League" id="league">
        <div className="wrapper">
          <h1>League for the Vertically Challenged</h1>

          {/* Schedule start */}
          <div className="table-wrapper">
            <h2>Schedule</h2>

            <table className="responsive-table striped">
              <thead>
                <tr>
                  <th />
                  <th>8:30 PM</th>
                  <th>9:00 PM</th>
                  <th>9:30 PM</th>
                  <th>10:00 PM</th>
                  <th>Bye</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Week 1 (2/10/20)</td>
                  <td>Black vs Blue</td>
                  <td>Green vs White</td>
                  <td>Black vs Blue</td>
                  <td>Green vs White</td>
                  <td>Red</td>
                </tr>
                <tr>
                  <td>Week 2 (2/17/20)</td>
                  <td>Green vs Blue</td>
                  <td>Red vs Black</td>
                  <td>Green vs Blue</td>
                  <td>Red vs Black</td>
                  <td>White</td>
                </tr>
                <tr>
                  <td>Week 3 (2/24/20)</td>
                  <td>White vs Black</td>
                  <td>Blue vs Red</td>
                  <td>White vs Black</td>
                  <td>Blue vs Red</td>
                  <td>Green</td>
                </tr>
                <tr>
                  <td>Week 4 (3/2/20)</td>
                  <td>Green vs Red</td>
                  <td>White vs Blue</td>
                  <td>Green vs Red</td>
                  <td>White vs Blue</td>
                  <td>Black</td>
                </tr>
                <tr>
                  <td>Week 5 (3/9/20)</td>
                  <td>White vs Red</td>
                  <td>Green vs Black</td>
                  <td>White vs Red</td>
                  <td>Green vs Black</td>
                  <td>Blue</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Schedule end */}

          {/* Standings start */}
          <div className="table-wrapper">
            <h2>Standings</h2>

            {/* <p>
              In the finals Team Purple defeated Team White: 85-76.
              Congratulations to the winner!
            </p>
            <div className="photos-wrapper">
              <div className="photos">
                <img
                  src="/images/league/l2-team-1-purple.jpg"
                  alt="Winner - Team Purple"
                />
                <div className="photos-caption">Team Purple</div>
              </div>
              <div className="photos">
                <img
                  src="/images/league/l2-team-2-white.jpg"
                  alt="Runner up - Team White"
                />
                <div className="photos-caption">Team White</div>
              </div>
            </div> */}

            <table className="responsive-table striped">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Blue</td>
                  <td>6</td>
                  <td>2</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>Red</td>
                  <td>4</td>
                  <td>4</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Green</td>
                  <td>4</td>
                  <td>4</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>Black</td>
                  <td>4</td>
                  <td>4</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>White</td>
                  <td>2</td>
                  <td>6</td>
                  <td>6</td>
                </tr>
              </tbody>
            </table>
            <p className="note">Last updated: March 12, 2020</p>
          </div>
          {/* Standings end */}

          {/* Roster start */}
          <div className="table-wrapper">
            <h2>Roster</h2>
            <table className="responsive-table striped">
              <tbody>
                <tr>
                  <th>Red</th>
                  <td>Andrew</td>
                  <td>Dre</td>
                  <td>Gia (C)</td>
                  <td>Jackie</td>
                  <td>Jose</td>
                </tr>
                <tr>
                  <th>Blue</th>
                  <td>Danny B</td>
                  <td>Glenn</td>
                  <td>Greg</td>
                  <td>Mo W.</td>
                  <td>Steven C. (C)</td>
                </tr>
                <tr>
                  <th>Black</th>
                  <td>Brandon (C)</td>
                  <td>Catherine</td>
                  <td>Dennis</td>
                  <td>Show</td>
                  <td>Steven R.</td>
                </tr>
                <tr>
                  <th>White</th>
                  <td>Casey</td>
                  <td>Hesh</td>
                  <td>Jean</td>
                  <td>Keith (C)</td>
                  <td>Mike W.</td>
                </tr>
                <tr>
                  <th>Green</th>
                  <td>Ashley</td>
                  <td>Bobby</td>
                  <td>Danny N.</td>
                  <td>Jerald</td>
                  <td>Marc (C)</td>
                </tr>
              </tbody>
            </table>
            <p className="note">(C) - captain</p>
          </div>
          {/* Roster end */}

          {/* Rules start */}
          <div className="table-wrapper">
            <h2>Summary of League</h2>

            <h3>FORMAT</h3>
            <p>
              Full-court 4-on-4 basketball (if shorthanded, teams must have at
              least 3 people on the court to not forfeit).
              <br />
              All matches consist of 2 teams in head-to-head play.
              <br />
              An official game ball will be provided at the start of every
              match. For warm up purposes, bring your own ball.
              <br />
              This is a full coed league. This means that one female player must
              be on the court at all times. If there is no female player, the
              team may elect to play 3 on 4.
              <br />
              Each team will be allowed one (1) 30 second timeout per game.
            </p>

            <h3>GAME LENGTH</h3>
            <p>
              Each game is played with a 25-minute running clock.
              <br />
              Clock does not stop when players shoot free throws.
              <br />
              There is an unwritten 30-second shot clock.
              <br />
              Clock stops in last minute of the game if the score is within 5
              points or less.
            </p>

            <h3>STANDINGS</h3>
            <p>
              Since each team plays two games during a match, they will end the
              night with a two game record (for example: 2-0, 1-1, 0-2 or with
              ties 0-0-2, 0-1-1, 1-0-1).
              <br />
              For each win, a team receives 3 points in the standings.
              <br />
              For each tie, a team receives 1 point in the standings.
              <br />
              No points are awarded for a loss.
              <br />
              Forfeits are -1 points in the standings for the team that
              forfeited.
            </p>

            <h3>TIE BREAKERS FOR PLAYOFFS/SEEDINGS (in order)</h3>
            <ul>
              <li>Head-to-head competition (among all tied teams)</li>
              <li>
                Record against other teams in or tied for playoffs (winning
                percentage – i.e., 0-1 is same as 0-2, but 1-3 is better than
                0-1)
              </li>
              <li>Number of wins</li>
              <li>Record against next best common opponent</li>
              <li>If team forfeited</li>
              <li>Scoring differential in common games</li>
              <li>"Coin-flip"</li>
            </ul>

            <h3>SCORING</h3>
            <p>
              Each regular field goal is worth 2 points.
              <br />
              A successful shot from behind the 3-point arc is worth 3 points.
              <br />
              Foul shots are worth 1 point.
            </p>

            <h3>REFEREES and FOULS</h3>
            <p>
              BFTVC will provide two referees for all games. They will keep the
              game time, call all fouls, and keep score in the case where subs
              are not present. the league will follow modified NCAA rules which
              include fouls, 3 in the key, backcourt, etc...
            </p>
            <p>
              Players will shoot (2) free throws if they are fouled in the act
              of shooting at any point during the game. A foul will be called if
              the player shooting free throws does not shoot within 7 seconds of
              getting the ball at the line.
            </p>
            <p>
              Players will shoot (1) free throw if they are fouled in the act of
              shooting and a basket is scored.
            </p>
            <p>
              Starting with the 10th team foul, teams will shoot 2 foul shots
              with change of possession on a made 2nd shot or a live ball on a
              missed 2nd shot. If the 10th foul occurs on a made shot, the
              individual will receive 1 foul shot.
            </p>
            <p>
              If a team has committed less than seven (7) fouls with 60 seconds
              or less remaining in the game, they will be permitted only one
              more foul before the other team shoots foul shots. If the first
              foul in the final minute is the 10th team foul, free throws will
              result as normal.
            </p>
            <p>
              Substitutions are allowed on dead ball whistles and must be called
              in by the referee on the court.
            </p>
            <p>
              There will be a 5 minute warmup period prior to the 1st game of
              the season and 2 minute in between games.
            </p>
            <p>
              Teams not playing will be asked to assist the referee in score
              keeping. Captains will designate a player to do so.
            </p>
            <p>
              We ask that all players report to the gym by 8:30 even if they
              have a later game. This is to ensure that we get the game started
              and ended on time.
            </p>
            <p>
              Please make sure to wear the appropriate shirt colors. If you dont
              have the exact shirt color, please wear a close approximation
              (Red/Pink/Orange) <strong>DO NOT WEAR GRAY</strong>. This is to
              ensure that the refs call the games easily.
            </p>
            <p>
              Any unsportsmanlike behavior may result in that player/team being
              suspended/removed from participating in all BFTVC leagues/Meetups.
            </p>
          </div>
          {/* Rules start */}
        </div>
      </div>
    );
  }
}

export default League;
