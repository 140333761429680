import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./Nav.css"

class Nav extends Component {
  scroll(e, id) {
    this.props.scroll(e, id)
    this.props.closeNav()
  }

  render() {
    return (
      <div className="App-nav">
        {this.props.mainItems.map((i, idx) => (
          <Link
            key={idx}
            to={`${i.id}`}
            className="Header-nav-item mobile"
            onClick={(e, id) => this.scroll(e, i.id)}
          >
            <div className="label">{i.label}</div>
          </Link>
        ))}

        <a
          href="https://www.meetup.com/Basketball-for-the-vertically-challenged/"
          className="Header-nav-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="label">Our Meetups</div>
        </a>

        <a
          href="http://blog.bftvc.org/"
          className="Header-nav-item"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="label">"Inside Stuff" blog</div>
        </a>
        <Link
          to="/league"
          className="Header-nav-item"
          onClick={() => this.props.closeNav()}
        >
          <div className="label">League</div>
        </Link>
      </div>
    )
  }
}

export default Nav
