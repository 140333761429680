import React, { Component } from 'react';
import './Roster.css';
import bobby from '../assets/profile-bobby.png';
import hadas from '../assets/profile-hadas.png';
import iveta from '../assets/profile-iveta.png';
import marc from '../assets/profile-marc.png';
import patrick from '../assets/profile-patrick.png';
import ross from '../assets/profile-ross.png';

class Roster extends Component {
  render() {
    return (
      <div className="Roster" id="roster">
        <div className="wrapper">
          <h1>Roster</h1>

          <div className="Roster-wrapper">
            <div className="Roster-item">
                <div className="Roster-item-image">
                  <img src={bobby} alt="Bobby" />
                </div>
                <div className="Roster-item-name">Bobby</div>
            </div>

            <div className="Roster-item">
                <div className="Roster-item-image">
                  <img src={hadas} alt="Hadas" />
                </div>
                <div className="Roster-item-name">Hadas</div>
            </div>

            <div className="Roster-item">
                <div className="Roster-item-image">
                  <img src={iveta} alt="Iveta" />
                </div>
                <div className="Roster-item-name">Iveta</div>
            </div>

            <div className="Roster-item">
                <div className="Roster-item-image">
                  <img src={marc} alt="Marc" />
                </div>
                <div className="Roster-item-name">Marc</div>
            </div>

            <div className="Roster-item">
                <div className="Roster-item-image">
                  <img src={patrick} alt="Patrick" />
                </div>
                <div className="Roster-item-name">Patrick</div>
            </div>

            <div className="Roster-item">
                <div className="Roster-item-image">
                  <img src={ross} alt="Ross" />
                </div>
                <div className="Roster-item-name">Ross</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Roster;
