import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Home from "./pages/Home"
import OurStory from "./pages/OurStory"
import Roster from "./pages/Roster"
import League from "./pages/League"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Nav from "./components/Nav"
import "./App.css"

const mainMenuItems = [
  {
    id: "home",
    label: "Home"
  },
  {
    id: "ourstory",
    label: "Our Story"
  },
  {
    id: "roster",
    label: "Roster"
  }
]

class App extends Component {
  constructor(props) {
    super(props)

    this.webContainer = null
  }

  componentDidMount() {
    this.webContainer = document.getElementById("App-body")
  }

  componentWillUpdate() {
    console.log("update")
  }

  toggleClass(el, className) {
    if (el.classList) {
      el.classList.toggle(className)
    } else {
      let classes = el.className.split(" ")
      let existingIndex = classes.indexOf(className)

      if (existingIndex >= 0) {
        classes.splice(existingIndex, 1)
      } else {
        classes.push(className)
      }

      el.className = classes.join(" ")
    }
  }

  removeClass(el, className) {
    if (el.classList) {
      el.classList.remove(className)
    } else {
      el.className = el.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      )
    }
  }

  scrollToY(scrollTargetY, speed) {
    scrollTargetY = scrollTargetY || 0
    speed = speed || 1500

    const container = document.getElementById("App-content")

    let easing = "easeOutSine"
    let scrollY = container.scrollTop
    let currentTime = 0

    let time = Math.max(
      0.1,
      Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
    )
    // const PI_D2 = Math.PI / 2;

    const easingEquations = {
      easeOutSine: function(pos) {
        return Math.sin(pos * (Math.PI / 2))
      }
    }

    function tick() {
      currentTime += 1 / 60

      let p = currentTime / time
      let t = easingEquations[easing](p)

      if (p < 1) {
        window.requestAnimationFrame(tick)
        container.scrollTop = scrollY + (scrollTargetY - scrollY) * t
      } else {
        container.scrollTop = scrollTargetY
      }
    }

    tick()
  }

  toggleNav(e) {
    e.preventDefault()
    this.toggleClass(this.webContainer, "opened")
  }

  closeNav() {
    this.removeClass(this.webContainer, "opened")
  }

  doScroll(e, id, delay = 0) {
    setTimeout(() => {
      if (!id || !document.getElementById(id)) return
      this.scrollToY(document.getElementById(id).offsetTop)
    }, delay)
  }

  render() {
    return (
      <Router>
        <div className="App">
          <div className="App-wrapper" id="App-body">
            <div className="App-header">
              <Header
                mainItems={mainMenuItems}
                toggleNav={e => this.toggleNav(e)}
                scroll={(e, id) => this.doScroll(e, id)}
              />
            </div>
            <div className="App-content">
              <div className="App-content-inset" id="App-content">
                <Switch>
                  <Route path="/league" exact component={League} />
                  <Route
                    render={() => (
                      <>
                        <Home />
                        <OurStory />
                        <Roster />
                      </>
                    )}
                  />
                </Switch>
                <Footer />
              </div>
            </div>
          </div>
          <Nav
            mainItems={mainMenuItems}
            toggleNav={e => this.toggleNav(e)}
            closeNav={() => this.closeNav()}
            scroll={(e, id) => this.doScroll(e, id)}
          />
        </div>
      </Router>
    )
  }
}

export default App
