import React, { Component } from 'react';
import './Footer.css';
import iconMeetup from '../assets/icon-meetup.svg';
import iconFacebook from '../assets/icon-facebook.svg';
import iconInstagram from '../assets/icon-instagram.svg';
import iconEmail from '../assets/icon-mail.svg';
import iconTwitter from '../assets/icon-twitter.svg';

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <div className="Footer">
        <div className="wrapper">
          <div className="Footer-legal">{ `© ${year} BFTVC` }</div>

          <div className="Footer-social">
            <a
              href="https://www.meetup.com/Basketball-for-the-vertically-challenged/"
              className="Footer-social-link meetup"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconMeetup} alt="Meetup" />
            </a>
            <a
              href="https://www.facebook.com/groups/bballshorties/"
              className="Footer-social-link facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconFacebook} alt="Facebook" />
            </a>
            <a
              href="https://twitter.com/bballshorties"
              className="Footer-social-link twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconTwitter} alt="Twitter" />
            </a>
            <a
              href="https://www.instagram.com/bballshorties/"
              className="Footer-social-link instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={iconInstagram} alt="Instagram" />
            </a>
            <a
              href="mailto:info@bftvc.org"
              className="Footer-social-link email"
            >
              <img src={iconEmail} alt="E-mail" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
