import React, { Component } from 'react';
import './OurStory.css';
import graphic from '../assets/our-story-graphic.svg';

class OurStory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFull: false
    };
  }

  toggleShowMore(event) {
    event.preventDefault();
    this.setState({ showFull: !this.state.showFull });
  }

  render() {
    return (
      <div className="OurStory" id="ourstory">
        <div className="wrapper">
          <div className="OurStory-title">
            <h1>Our Story</h1>
            <img src={graphic} alt="Graphic" />
          </div>
          <div className="OurStory-text">
            <p><strong>{ `We are a co-ed basketball group, for people of all ages, genders, and levels of experience. We play competitive, but easygoing, pick-up games. Our only requirement is that you be 5'8" or under to play!` }</strong></p>

            <p>{ `Way back In 2010 when the iPhone 4 was the freshest new gadget on the streets, Hadas decided she wanted to play some pickup hoops on the regular in the basketball capital of the world. But she had a problem: who to play with when you're only five feet tall? But the never deterred Hadas didn't despair, figuring there must be a group of short basketball players out there somewhere. After all, this was New York City! Surprisingly, she found no such thing, so she took the plunge, and set up a group on Meetup.com called Basketball for the Vertically Challenged. And so our BFTVC story begins.` }</p>

            <p>{ `The first few games were sparse. Bobby showed up for the second meetup, and together they played one-on-one. The next meetup resulted in a couple more shorties in attendance, and within a few months, BFTVC had a waiting list every Saturday to play. Apparently Hadas’ hunch was right: there are quite a few shorties in the Concrete Jungle that want to play with a clear shot at the hoop.` }</p>

            <p>{ `Over time, we finessed our approach (if not our actual skills!). We have made a home for people excited about the game of basketball, want to push themselves and play hard, but keep things friendly, easy-going, and inclusive to people of all skill levels and genders... as long as you're not over 5'8"! With BFTVC, shorties have thrived on the court, made friends off the court, kept a little more fit, all while having a lot more fun...` }</p>

            <p>{ `We hope you'll join us, too.` }</p>

            { /*
            <div className={this.state.showFull ? '' : 'hidden'}>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis iusto veniam debitis doloribus nam necessitatibus nisi rerum totam repellat, magni, deleniti enim illum voluptates asperiores, beatae praesentium suscipit, odio. Nihil.</p>
              <p>Adipisci numquam eveniet ratione a vitae quis, ea quod excepturi tenetur quos libero dolore aliquid recusandae delectus perspiciatis. Error molestias necessitatibus quam ipsam, cumque itaque saepe asperiores libero eius, consequuntur.</p>
              <p>Quae amet dolore porro eos nisi iure expedita, consequatur quo et reiciendis corrupti repellat itaque, quaerat accusantium nostrum beatae veritatis, perspiciatis. Eaque, nulla! Vero aliquid saepe, vitae autem fugit odit.</p>
            </div>

            <p className='OurStory-morelink'>
              <a
                href="#more"
                onClick={e => this.toggleShowMore(e)}
              >{ this.state.showFull ? 'Read less' : 'Read more'}</a>
            </p>
            */}
          </div>
        </div>
      </div>
    );
  }
}

export default OurStory;
